.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.footer-dark{
  padding-top:10px;
  background-color:#4c4c4c;
  color:#fff;
}

.footer-light{
  background-color: #ef6b38;
  color:#fff;
  padding-bottom: 10px;
}

.footer-nav{
min-height: 200px;
padding-top:50px;
}

.card-columns {
  column-count: 3;
}


 .navItem {
  background-color: lemonchiffon;
  color:#222;
  
}

 .stripeNavItem{
  background-color: navajowhite;
  color:#333;
}

.navItem:hover{
  cursor: pointer;
  background-color: #4c4c4c;
  color:#fff;
}

.testNavbar{
  background-color: #fff!important;
  box-shadow: 0 4px 18px 0 rgba(0,0,0,.12), 0 7px 10px -5px rgba(0,0,0,.15);
}

.mainbar{
  display: flex;
}

.menu-content{
  display:flex;
  background-color: #fff!important;
}

.menu-bar{
  display: flex;
}

.menu-item-content{
  flex-direction: row;
  width:100%;
}

.link-item{
  color:#a9352e
}

.link-item:hover{
  text-decoration: none;
  color:#ef6b38
}



.sidenav {
  display: flex; /* Will be hidden on mobile */
  grid-area: sidenav;
  height: 100%;
}

.sidenav__list {
  padding: 0;
  list-style-type: none;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 /* Non-mobile styles, 750px breakpoint */
 @media only screen and (min-width: 46.875em) {
  /* Show the sidenav */

  .sidenav {
    display: flex;
    flex-direction: column;
  }
}
.menu-bar .menu-icon {
  position: fixed;
  align-items: center;
  width:20px;
  z-index: 1;
  cursor: pointer;
  color: #ef6b38;
  height: 30px;
  
}

.menu-icon .menuIcon{
  background-color: #ddd;
  padding:4px;
  font-size: 30px;
  width:45px;
  height: 45px;
  border:1px solid #666;
  border-radius: 75px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

/* Mobile-first side nav styles */
.sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  width: 92.5%;   
  position: absolute;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);  
   */
  z-index: 2; 
  transform: translateX(-110%);
  transition: all .6s ease-in-out;
  background-color: #fff;
  height: auto;
}

.sidenav__close-icon {
  position: absolute;
  visibility: visible;
  top: 8px;
  right: 12px;
  cursor: pointer;
  font-size: 20px;
  color: #111;
}

/* The active class is toggled on hamburger and close icon clicks */
.sidenav.active {
  transform: translateX(0);
}

@media only screen and (min-width: 46.875em) {
  .sidenav {
    position: relative; /* Fixed position on mobile */
    transform: translateX(0);
  }

  .sidenav__close-icon {
    visibility: hidden;
  }

  .menu-icon {
    visibility: hidden;
  }
}


